// // export default CombinedPortfolio;

// import React, { useState, useEffect, useCallback, useRef ,useContext } from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { Base_URL } from '../../../Components/BaseURL.js';
// import {
//   PieChart, Pie, Cell, Tooltip, ResponsiveContainer
// } from 'recharts';
// import '../Portfolio.css';
// import { useWindowSize } from "@uidotdev/usehooks";
// import { useNavigate, useLocation, Outlet } from 'react-router-dom';
// // import fetchCPrice from './fetchCurrentPrice';
// import html2canvas from 'html2canvas';
// import { chartColors } from "../colors.js";
// import { FadeLoader } from 'react-spinners';
// import Typography from '@mui/material/Typography';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import moment from 'moment';
// import { usePortfolio } from "../../../PortfolioContext";
// import { Base_URL_TV } from "../../../Components/BaseURL_TV.js";
// import { calcDate } from '../../calcDate';
// import ImageDisplay from '../../ImageDisplayprofile';
// import PortfolioCreation from '../PortfolioCreation';
// import Todayspricevolume from '../Todayspricevolume';
// import Linechart from '../linechart';
// import Finacialinfo from '../FinancialInfo';
// import CSENEWS from '../CSENews.js';
// import DividentTracker from '../DividentTracker';
// import Marketsentiment from '../MarketSentiment';
// import researchinfo from '../ResearchInfo';
// import CurrentPortfolio from '../CurrentPortfolio';
// import Button from '@mui/material/Button';
// import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { TwitterShareButton } from "react-share";
// import domtoimage from "dom-to-image";
// import { BaseURL_twitter } from '../../../Components/BaseURL_twitter.js';
// function Browser({ size }) {
//   return (
//     <div
//       data-testid="browser"
//       className="browser"
//       style={{ width: size.width / 4, height: size.height / 4 }}
//     />
//   );
// }
// const CombinedPortfolio = ({ label }) => {
//   const { stocks, setStocks, currentPrices, setCurrentPrices , currentVolumes , setcurrentVolumes } = usePortfolio();
//   const size = useWindowSize();
//   const [total, setTotal] = useState(0);
//   const [totalpfsize, setTotalpfsize] = useState(0);
//   const [totalqty, setTotalqty] = useState(0);
//   const [Tots, setTots] = useState([]);
//   const navigate = useNavigate();
//   const [pfcompStrings, setPfcompStrings] = useState([]);
//   const [gainStrings, setGainStrings] = useState([]);
//   const chartRef = useRef(null);
//   const backgColor = chartColors;  // Ensure this is properly defined
//   const [totalGain, setTotalGain] = useState(0);  // Corrected naming
//   const [isLoading, setIsLoading] = useState(true);
//   const crdate = localStorage.getItem('crdate');
//   const today = new Date();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [anchorEl2, setAnchorEl2] = useState(null);
//   const location = useLocation();
//   // Formatting dates
//   const formattedToday = moment(today).format('YYYY-MM-DD');
//   const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
//   const dateDifference = calcDate(formattedToday, formattedCrDate);
//   const [chartCenter, setChartCenter] = useState({ x: 0, y: 0 }); // Defined properly
//   const username = localStorage.getItem('unm');
//   const userid = localStorage.getItem('userid');
//   const [count, setCount] = useState(0);
//   const [currentPageName, setCurrentPageName] = useState('');
//   const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
 
//   useEffect(() => {
//     document.title = "stockwiseanalytics.com - Portfolio"; // Dynamically changes the title
//   }, []);


// /////////////
//     const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  
//     const updateWindowSize = () => {
//       setWindowSize({ width: window.innerWidth, height: window.innerHeight });
//     };
  
//     useEffect(() => {
//       window.addEventListener('resize', updateWindowSize);
  
//       return () => {
//         window.removeEventListener('resize', updateWindowSize);
//       };
//     }, []);
//   // Fetch portfolio data
//   useEffect(() => {
//     const fetchPFData = async () => {
//       setIsLoading(true);
//       try {
//         const res = await axios.get(Base_URL + "/portfolio/stocks", {
//           params: { username },
//         });
//         const data = res.data;
//         setStocks(data);
//         const symbols = data.map((stock) => stock.ctick);
//         localStorage.setItem("symbolstring", symbols.join(","));
        
//         const priceResponse = await axios.get(
//           `${Base_URL_TV}/tvapimmvt?symbols=[${symbols.join(",")}]`
//         );
//         setCurrentPrices(priceResponse.data[0]);

//         const volumeResponse = await axios.get(
//           `${Base_URL_TV}/tvapi?symbols=[${symbols.join(",")}]`
//         );
        
//         // console.log('----volumes---'+ JSON.stringify(volumeResponse.data[0].volume));
//         // setcurrentVolumes(volumeResponse.data.volume);


//         const url = `${Base_URL_TV}/tvapi?symbols=[${symbols.join(",")}]`;
//         fetch(url)
//           .then(response => response.json())
//           .then(data => {
//             console.log("Received data:", data);
//             const volumes = data.reduce((acc, stock) => {
//               if (!stock.error) {
//                 acc[stock.symbol] = stock.volume;
           
//                    }
//               return acc;
//             }, {});
//              setcurrentVolumes(volumes);
//           })

//       } catch (error) {
//         console.error(error);
//         toast.error("Failed to fetch data");
//       } finally {
//         setIsLoading(false);
//       }
//     };
  
//     fetchPFData();
//   }, [setStocks, setCurrentPrices]);

// //   const calculateTotal = useCallback(async () => {
// //     try {
// //       let totalValue = 0;
// //       let totalPFSizeValue = 0;
// //       let calculatedTots = [];
// //       let newGainStrings = [];
// //       let newPfcompStrings = [];
// //       let newlgndgainpercentage = [];
      
// //       for (let i = 0; i < stocks.length; i++) {
// //         const stock = stocks[i];
// //         const label = stock.cname;
// //         const quantity = stock.csemco_quantity;
// //         const avgPrice = stock.avgprice;
// //         const tick = stock.ctick;
// //         await delay(2000); 
// //         // Fetch current price for each stock
// //         const matchingData = await fetchCPrice(tick);

// //         if (matchingData) {
// //           const lcpap = parseFloat(matchingData) - parseFloat(avgPrice);
// //           const gain = lcpap * quantity;
// //           const gainString = gain.toFixed(2);
// //           totalValue += Math.abs(gain);
// //           calculatedTots.push(((lcpap * 100) / parseFloat(avgPrice)).toFixed(2));
// //           calculatedTots.push(gain.toFixed(2));
// //           newGainStrings.push(Math.round(gainString));
// //           const pfcomp = parseFloat(avgPrice) * quantity;
// //           const lgndgainpercentage = ((lcpap * 100) / parseFloat(avgPrice)).toFixed(2);
// //           newlgndgainpercentage.push(lgndgainpercentage);
// //           const pfcompString = Math.round(pfcomp);
// //           totalPFSizeValue += pfcomp;
// //           newPfcompStrings.push(Math.round(pfcomp));
// //         }
// //       }
// //       setTotalpfsize(totalPFSizeValue);
// //       setTotalGain(totalPFSizeValue);
// //       setTotal(totalValue);
// //       //setTots(calculatedTots);
// //       setTots(newGainStrings);
// //       setPfcompStrings(newPfcompStrings);
// //     } catch (error) {
// //       console.error('Error calculating total:', error);
// //     }
// //   }, [stocks]);
  
//   const calculateTotal = useCallback(async () => {
//     try {
//       let totalValue = 0;
//       let totalPFSizeValue = 0;
//       const calculatedTots = [];
//       const newGainStrings = [];
//       const newPfcompStrings = [];
//       const newlgndgainpercentage = [];


  
//       stocks.forEach((stock) => {
//         const matchingPrice = currentPrices.find(
//           (close_prices) =>
//             close_prices.symbol.toLowerCase() === stock.ctick.toLowerCase()
//         );
    
//         if (matchingPrice) {
//             const lcpap = parseFloat(matchingPrice.close_prices.cp90days) - parseFloat(stock.avgprice);
//           const gain = lcpap * stock.csemco_quantity;
//           totalValue += Math.abs(gain);
  
//           calculatedTots.push(((lcpap * 100) / parseFloat(stock.avgprice)).toFixed(2));
//           newGainStrings.push(Math.round(gain.toFixed(2)));
  
//           const pfcomp = parseFloat(stock.avgprice) * stock.csemco_quantity;
//           totalPFSizeValue += pfcomp;
//           newPfcompStrings.push(Math.round(pfcomp));
//           newlgndgainpercentage.push(
//             ((lcpap * 100) / parseFloat(stock.avgprice)).toFixed(2)
//           );
//         }
//       });
  
//       setTotalpfsize(totalPFSizeValue);
//       setTotalGain(totalPFSizeValue);
//       setTotal(totalValue);
//       //setTots(calculatedTots);
//       setTots(newGainStrings);
//       setPfcompStrings(newPfcompStrings);
//     } catch (error) {
//       console.error("Error calculating total:", error);
//     }
//   }, [stocks, currentPrices]);

    
//   useEffect(() => {
//     if (currentPrices.length > 0) {
//       calculateTotal();
//     }
//   }, [currentPrices, calculateTotal]);

//   useEffect(() => {
//     if (totalGain> 0) {
//       setIsLoading(false);
//     }
//   }, [stocks, totalGain]);

  

//   const chartData = stocks.map((stock, index) => ({
//     name: stock.cname,
//     value: stock.csemco_quantity * stock.avgprice,
//     tick: stock.ctick,
//     color: backgColor[index]
//   }));

//  useEffect(() => {
//     const updateChartCenter = () => {
//       if (chartRef.current && typeof chartRef.current.getBoundingClientRect === 'function') {
//         const chartBoundingBox = chartRef.current.getBoundingClientRect();
//         const centerX = chartBoundingBox.width / 2;
//         const centerY = chartBoundingBox.height / 2;
//         setChartCenter({ x: centerX, y: centerY });
//       }
//     };
//     updateChartCenter();
//     window.addEventListener('resize', updateChartCenter);
//     return () => window.removeEventListener('resize', updateChartCenter);
//   }, []);
//   const ColoredLine = ({ color }) => (
//     <hr
//       style={{
//         color: 'rgba(256,256,256, 0.3)',
//         backgroundColor: 'rgba(256,256,256, 0.3)',
//         height: 1,
//       }}
//     />
//   );
//   ////////
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   function navigateTo(path) {
//     navigate(path);
//   }

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleClose2 = () => {
//     setAnchorEl2(null);
//   };
//   function handleSelect_apd(evt) {
//     navigateTo("/portfolio_apd");
//   }

//   function handleSelect_g_t(evt) {
//     navigateTo("/portfolio_today");
//   }

//   function handleSelect_g_lm(evt) {
//     navigateTo("/portfolio_last-month");
//   }

//   function handleSelect_g_ltm(evt) {
//     navigateTo("/portfolio_last-three-months");
//   }
//   // const handleShare = () => {
//   //   let node = document.getElementById('Doughnutrefid');
//   //   domtoimage.toPng(node)
//   //     .then(dataUrl => {
//   //       axios.post(`${BaseURL_twitter}`, { dataUrl: dataUrl })
//   //         .then(res => {
//   //           const url = res.data.imageUrl;
//   //           console.log('---url---',url);
//   //           const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
//   //           window.open(twitterURL, "twitter");
//   //         })
//   //         .catch(err => console.log(err, "Error trying to tweet"));
//   //     })
//   //     .catch(err => console.log(err));
//   // };

//   const handleShare = () => {
//     // Get the node element from DOM (your chart or image element)
//     let node = document.getElementById('Doughnutrefid');
    
//     // Convert the node to a PNG image using dom-to-image
//     domtoimage.toPng(node)
//       .then(dataUrl => {
//         // Post the image (base64 encoded) to your backend for uploading to Twitter
//         axios.post("https://api.stockwiseanalytics.com/image/imagetotweet", { dataUrl: dataUrl })
//           .then(res => {
//             // Assuming your backend returns the tweet URL
//             const url = res.data.imageUrl;  // Ensure the backend is sending the correct URL
//             const twitterURL = `https://twitter.com/share?url=${encodeURIComponent(url)}&text=Check%20out%20this%20image!&hashtags=yourHashtags`;
            
//             // Open the Twitter share URL in a new window
//             window.open(twitterURL, "twitter");
//           })
//           .catch(err => {
//             console.error("Error trying to tweet", err);
//             alert("Failed to share the image on Twitter.");
//           });
//       })
//       .catch(err => {
//         console.error("Error converting image", err);
//         alert("Failed to convert the image for sharing.");
//       });
//   };

  
//   const downloadChartAsImage = async () => {
//     const datapdf = document.getElementById('Doughnutrefid');
//     const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
//     try {
//       html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
//         var link = document.createElement('a');
//         link.href = canvas.toDataURL('image/png');
//         // link.download = `portfolio_gainsnapshot_${currentDate}.png`;
//         link.download = `portfolio_gainsnapshot_${currentDate}.png`;
//         link.click();
//       });
//     } catch (error) {
//       console.error('Error downloading the chart:', error);
//       toast.error('Failed to download chart');
//     }
//   };
  
//   const handleMenuClick2 = (event) => {
//     setAnchorEl2(event.currentTarget);
//   };

//   return (
//     <>
//    <div class="container-fluid px-4">
//    <div className="row align-items-center bg-black  text-white centered-text-profilesettings"  style={{
//            backgroundblendmode: 'exclusion',
//            background: 'url(/images/Rectangle-2.png) lightgray 50% / cover no-repeat' ,
//         width: `100`,
//       }}>
//            <div className="p-4"></div><div className="p-4"></div><div className="p-4"></div><div className="p-4"></div>
//      <div class="col-lg-3 col-sm-12 col-xs-12">
//             <div class="center-block">
//             <div className="col" id="CSEMaverick">        
//     <table className="table-borderless">  
//          <tr>
//         <td  colSpan="2" width=""></td>&nbsp;</tr>
//        <tr>      
//         <td className="d-flex justify-content-start border-0">
//         <ImageDisplay imageId={userid}/>
//         </td>
//         <td className="justify-content-start  text-nowrap">
//         <div className="row">
//          <div className="online"><img className="rounded-1 span4 center-block"  src="/images/profile/vgeenclock.png"/><div className="ps-4">Online</div></div>
//       </div>
//       <div className="row">                                            
//         <div className="joined-2-years-ago"><img className="rounded-1 span4 center-block" src="/images/profile/vbag.png"/>
//         <div className="ps-4">Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
     
//         </div>
//       </div>
//         </td>
//     </tr>

// </table>
// </div>

//             </div>
//         </div>
//         <div class="col-lg-6 col-sm-12 col-xs-12">
//             <div class="text-center">
//               <div className="img-fluid d-md-none mx-auto d-block pt-2"></div>
//             <p class="lh-sm">
//   <span className="trackcss1">Track your investments</span>
//   <span className="trackcss2">,</span>
//   <span className="trackcss1"> assess risk</span>
//   <span className="trackcss2"> and </span>
//   <span className="trackcss1">make informed decisions</span>
//   <span className="trackcss2 font-thin"> with our portfolio analysis tools. </span>
//   <span className="trackcss1">Visualize your portfolio's performance</span>
//   <span className="trackcss2"> with intuitive charts and metrics, empowering you to </span>
//   <span className="trackcss1">optimize your investments</span>
//   <span className="trackcss2"> and </span>
//   <span className="trackcss1">achieve your financial goals with confidence</span>
//   <span className="trackcss2">.</span>
//   </p>

//             </div>
//         </div>
//         <div class="col-lg-3 col-sm-12 col-xs-12">
//             <div class="center-block">&nbsp;</div>
//         </div>
//         <div className="p-4"></div><div className="p-4"></div>
// </div>
// </div>

//     {/* <div className="container-fluid">
// <div className="row align-items-start bg-black  text-white centered-text-profilesettings"  style={{
//            backgroundblendmode: 'exclusion',
//            background: 'url(/images/Rectangle-2.png) lightgray 50% / cover no-repeat' ,
//         width: `100`,
//       }}>
// <div className="p-1"></div><div className="p-4"></div><div className="p-4"></div><div className="p-2"></div>
// <div className="row">
// <div className="col-md-3">
//     <div className="col" id="CSEMaverick">        
//     <table className="table-borderless">  
//          <tr>
//         <td  colSpan="2" width=""></td>&nbsp;</tr>
//        <tr>      
//         <td className="d-flex justify-content-start border-0" style={{paddingLeft: '30px'}}>
//         <ImageDisplay imageId={userid}/>
//         </td>
//         <td className="justify-content-start  text-nowrap">
//         <div className="row">
//          <div className="online"><img className="rounded-1 span4 center-block" style={{paddingLeft: '24px'}} src="/images/profile/vgeenclock.png"/><div className="ps-4">Online</div></div>
//       </div>
//       <div className="row">                                            
//         <div className="joined-2-years-ago"><img className="rounded-1 span4 center-block" style={{paddingLeft: '24px'}} src="/images/profile/vbag.png"/>
//         <div className="ps-4">Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
     
//         </div>
//       </div>
//         </td>
//     </tr>

// </table>
// </div>
// </div>
// <div className="col-md-6">
// <div className="col text-center">
// <div className="d-flex align-items-center justify-content-center">
// <p class="lh-sm">
//   <span className="trackcss1">Track your investments</span>
//   <span className="trackcss2">,</span>
//   <span className="trackcss1"> assess risk</span>
//   <span className="trackcss2"> and </span>
//   <span className="trackcss1">make informed decisions</span>
//   <span className="trackcss2 font-thin"> with our portfolio analysis tools. </span>
//   <span className="trackcss1">Visualize your portfolio's performance</span>
//   <span className="trackcss2"> with intuitive charts and metrics, empowering you to </span>
//   <span className="trackcss1">optimize your investments</span>
//   <span className="trackcss2"> and </span>
//   <span className="trackcss1">achieve your financial goals with confidence</span>
//   <span className="trackcss2">.</span>
//   </p>
// </div>
//    </div>
//   </div>
//   <div className="col-md-3"></div>
//     </div>
//     <div className="p-4"></div>  
//     <div className="p-4"></div>  
//     <div className="col">   
//     </div>
//   </div>
//   </div>  */}
//   <div class="container-fluid px-4">
//   <div className="row  px-4">
//   <p className="fininfo_title text-white fs-6 fw-bold font-family-sans-serif px-3">            
//     PORTFOLIO PERFORMANCE
//       </p>
//       </div>
//       <div className="row container">
//       <div className="col-md-2 p-0"></div>
//           <div className="col-md-8 p-0">
//           <Breadcrumbs aria-label="breadcrumb" sx={{ 
//                         fontSize: '0.8rem', 
//                     fontWeight: '700', 
//                     fontFamily: 'sans-serif',
//                     position: 'realative', 
//                     left: '1%',
//                     bottom:'2%',
//                     zIndex: 'tooltip', //tooltip:1500 
//                 }} >
//                         <Link underline="hover" color="white" href="/" style={{ fontFamily: 'Montserrat !important'}}>
//             Home</Link>
//         <Link underline="hover" color="white" href="/portfolio" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>
//         Portfolio Analytics</Link>
//         <Link underline="hover" color="white" href="/portfolio_gain" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>
//         Gain</Link>
//           <Typography color="white" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>Last Three Months</Typography>
//    </Breadcrumbs>
//           </div>
//           <div className="col-md-2 p-0"></div>
//         </div>
//         <div className="row">
//     <div className="col-md-2 p-0"></div>
//         <div className="col-md-8 p-0">
//       <div className="col-md-8 p-3 d-grid gap-2 d-md-flex d-flex justify-content-center  rounded-top" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',width: '100%'}}>
//       <Button
//           className="btn me-md-2 basic-button text-capitalize font-sans-serif fs-6 portfoliobtn btn-sq w-100"
//           onClick={handleMenuClick}
//                >
//           Gain<ArrowDropDown />
//         </Button>

//         <Menu
//           id="basic-menu"
//           className="btn portfoliobtn"
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleClose}
//         >
//           <MenuItem
//             onClick={() => {
//               navigateTo("/portfolio");
//               handleClose();
//             }}
//             className="menu-item portfoliobtnsel"
//           >
//             Gain Snapshot
//           </MenuItem>
//           <MenuItem
//             onClick={handleSelect_apd}
//             className="menu-item portfoliobtnsel"
//           >
//             Annual Projected Dividend
//           </MenuItem>
//         </Menu>

//         <Button
//           className="btn portfoliobtn me-md-2 basic-button text-capitalize font-sans-serif fs-6  btn-sq w-100"
//           onClick={handleMenuClick2}
//           style={{ fontFamily: 'sans-serif' }}
//         >
//          Till Today&nbsp;&nbsp;&nbsp;<ArrowDropDown />
//         </Button>

//         <Menu
//           id="basic-menu2"
//           className="btn portfoliobtn"
//           anchorEl={anchorEl2}
//           open={Boolean(anchorEl2)}
//           onClose={handleClose2}
//         >
//      <MenuItem onClick={handleSelect_g_t} className="menu-item portfoliobtnsel">Till Today</MenuItem>
//             {JSON.stringify(dateDifference.total_days) > 30  && (            
//             <MenuItem onClick={handleSelect_g_lm} className="menu-item portfoliobtnsel">Last Month</MenuItem>
//             )}
//              {JSON.stringify(dateDifference.total_days) > 90  && ( 
//             <MenuItem onClick={handleSelect_g_ltm} className="menu-item portfoliobtnsel">Last Three Months</MenuItem>
//           )}
//         </Menu>

//         {/* <TwitterShareButton
//           url={"https://www.stockwiseanalytics.com/"}
//           title="Hey, I just shared my stocks"
//           hashtags={["", ""]}
//           onClick={handleShare}
//           className="font-family-sans-serif  me-md-2"
//         >
//           <i className="btn portfoliobtn bi bi-twitter-x font-family-sans-serif fs-6" style={{ fontStyle: 'normal' }}>
//             &nbsp;&nbsp;&nbsp;Share
//           </i>
//         </TwitterShareButton> */}
//         <button className="btn portfoliobtn bi bi-download font-family-sans-serif fs-6 btn-sq css-1ujsas3" type="button" onClick={downloadChartAsImage}>
//           &nbsp;&nbsp;&nbsp;Download
//         </button>
//       </div>
// </div>
// <div className="col-md-2 p-0"></div>
// </div>

//         <div className="row" id="Doughnutrefid">
//         <div className="col-md-2 p-0"></div>
//          <div className="col-md-4 p-0">
//             <div ref={chartRef} style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)', height: "325px", position: "relative", marginBottom: "0%", padding: "0%" }} className="container d-flex align-items-center justify-content-center p-0 text-white font-family-sans-serif fontSize='12px'">
//               {isLoading && (
//                 <div className="spinner-container">
//                   <FadeLoader color="#ffffff" loading={isLoading} size={50} />
//                 </div>
//               )}
// <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
// <ResponsiveContainer width="100%" height={400}>
// <PieChart ref={chartRef}>
//   <Pie
//     data={chartData}
//     cx="50%"
//     cy="50%"
//     fontSize="12px"
//     innerRadius={90}
//     outerRadius={120}
//     fill="#8884d8"
//     dataKey="value"
//     label={({ name, value }) => `${parseInt(value.toFixed(2)).toLocaleString()} LKR`}
//   >
//     {chartData.map((entry, index) => (
//       <Cell
//         key={`cell-${index}`}
//         fill={backgColor[index % backgColor.length]}
//       />
//     ))}
//   </Pie>

//   <Tooltip
//     wrapperStyle={{ fontFamily: 'sans-serif', fontSize: '12px' }}
//     content={({ active, payload }) => {
//       if (active && payload && payload.length) {
//         const { name, value } = payload[0];
//         return (
//           <div style={{ backgroundColor: 'white', fontFamily: 'sans-serif' ,padding: '5px', borderRadius: '5px' }}>
//             {/* <p style={{ margin: 0 ,fontFamily: 'Montserrat' }}>{`${name}: ${value.toFixed(2)} LKR`}</p> */}
//             <p style={{ fontFamily: 'sans-serif',fontSize: '10px', color: '#000000',fontWeight: 'bold'}}>{`${name}`}</p>
//             <p style={{ fontFamily: 'sans-serif',fontSize: '10px', color: '#000000' }}>{`${parseInt(value.toFixed(2)).toLocaleString()} LKR`}</p>
//           </div>
//         );
//       }
//       return null;
//     }}
//   />

// <text x="50%" y="43%" fontSize="18px" textAnchor="middle" style={{ textAlign: 'center', color: 'white', fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 700, lineHeight: '22px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
//     {totalGain !== 0 ? `${((parseInt(total) * 100) / parseInt(totalGain)).toFixed(2)}%` : '0%'}
//   </text>
  
//   <text x="50%" y="50%" fontSize="11px" style={{ color: 'white', fontsize: '11px', fontFamily: 'sans-serif', fontWeight: 300, lineHeight: '16px', whiteSpace: 'pre-wrap',}} textAnchor="middle" fill="white" dominantBaseline="middle">
//     Portfolio Size
//   </text>
  
//   <text x="50%" y="57%" fontSize="14px" textAnchor="middle" style={{color: 'white', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 500, lineHeight: '16px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
//     {Math.trunc(totalGain.toFixed(2)).toLocaleString()} LKR
//   </text>
// </PieChart>
//       </ResponsiveContainer>
// </div>
//             </div>
//           </div> 
//        <div className="col-md-4 d-flex align-items-center justify-content-center font-family-sans-serif" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',height: '325px', overflow: 'auto' }}>
//             <ul>
//             <table className="font-family-sans-serif text-white" style={{ fontSize: '12px',fontFamily: 'sans-serif'}}>
//                <thead>
//                  <tr>
//                    <th></th>
//                    <th className="text-start font-family-sans-serif">Stock</th>
//                   <th className="text-center font-family-sans-serif">Gain(LKR)</th>
//                  </tr>
//                </thead>
//                <tbody>
//                {stocks.map((stock, index) => (
//                   <tr key={index}>
//                     <td><li style={{ backgroundColor: backgColor[index], color: backgColor[index],fontFamily: 'sans-serif',fontSize:'20px', lineHeight: '14px', verticalAlign:'middle'}}></li></td>
//                     <td style={{ fontFamily: 'sans-serif',color: '#ffffff' }} className="text-nowrap text-start">{stock.cname}&nbsp;</td>
//                     <td className="text-nowrap text-end" style={{ fontFamily: 'sans-serif' }}>&nbsp;{(parseInt(Tots[index])).toLocaleString()}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>

//             </ul>
//           </div>
//           <div className="col-md-2 p-0"></div>
//         </div>        
//       </div>
//       <div className="p-2"></div>  
//       <div className="p-2"></div>  
//       <div className="p-2"></div>  
//       <div className="p-2"></div>  
//        <div className="container-fluid ">
//           <ColoredLine />
//           <div className="row">
//             <div className="col-md-7">
//             <CurrentPortfolio/>
//             </div>
//             <div className="col-md-5">
//               <PortfolioCreation />
//             </div>
//           </div>
//                <ColoredLine />
//           <div className="row">
//             <div className="col-md-6 pt-2">
//               <Todayspricevolume />
//             </div>
//             <div className="col-md-6 pt-2">
//               <Linechart />
//             </div>
//           </div>
  
//            <ColoredLine />
//           <div className="row">
//             <div className="col-md-12">
//               <Finacialinfo />
//             </div>
//           </div>
//           <ColoredLine />
 
//           <div className="row">
//             <div className="col-md-12">
//               <CSENEWS />
//             </div>
//           </div>
//           <ColoredLine />

//           <div className="row">
//             <div className="col-md-12">
//               <researchinfo />
//             </div>
//           </div> 
//           <div className="row">
//             <div className="col-md-12">
//               <DividentTracker />
//             </div>
//           </div> 
//           <div className="row">
//             <div className="col-md-12">
//               <Marketsentiment />
//             </div>
//           </div> 
//       </div> 
//       <Browser size={size} />
//     </>
//   );
// };

// export default CombinedPortfolio;
// export default CombinedPortfolio;

import React, { useState, useEffect, useCallback, useRef ,useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base_URL } from '../../../Components/BaseURL.js';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import '../Portfolio.css';
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
// import fetchCPrice from './fetchCurrentPrice';
import html2canvas from 'html2canvas';
import { chartColors } from "../colors.js";
import { FadeLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import moment from 'moment';
import { usePortfolio } from "../../../PortfolioContext";
import { Base_URL_TV } from "../../../Components/BaseURL_TV.js";
import { calcDate } from '../../calcDate';
import ImageDisplay from '../../ImageDisplayprofile';
import PortfolioCreation from '../PortfolioCreation';
import Todayspricevolume from '../Todayspricevolume';
import Linechart from '../linechart';
import Finacialinfo from '../FinancialInfo';
import CSENEWS from '../CSENews.js';
import DividentTracker from '../DividentTracker';
import Marketsentiment from '../MarketSentiment';
import researchinfo from '../ResearchInfo';
import CurrentPortfolio from '../CurrentPortfolio';
import Button from '@mui/material/Button';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TwitterShareButton } from "react-share";
import domtoimage from "dom-to-image";
import { BaseURL_twitter } from '../../../Components/BaseURL_twitter.js';
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
const CombinedPortfolio = ({ label }) => {
  const { stocks, setStocks, currentPrices, setCurrentPrices , currentVolumes , setcurrentVolumes } = usePortfolio();
  const size = useWindowSize();
  const [total, setTotal] = useState(0);
  const [totalpfsize, setTotalpfsize] = useState(0);
  const [totalqty, setTotalqty] = useState(0);
  const [Tots, setTots] = useState([]);
  const navigate = useNavigate();
  const [pfcompStrings, setPfcompStrings] = useState([]);
  const [gainStrings, setGainStrings] = useState([]);
  const chartRef = useRef(null);
  const backgColor = chartColors;  // Ensure this is properly defined
  const [totalGain, setTotalGain] = useState(0);  // Corrected naming
  const [isLoading, setIsLoading] = useState(true);
  const crdate = localStorage.getItem('crdate');
  const today = new Date();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const location = useLocation();
  // Formatting dates
  const formattedToday = moment(today).format('YYYY-MM-DD');
  const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
  const dateDifference = calcDate(formattedToday, formattedCrDate);
  const [chartCenter, setChartCenter] = useState({ x: 0, y: 0 }); // Defined properly
  const username = localStorage.getItem('unm');
  const userid = localStorage.getItem('userid');
  const [count, setCount] = useState(0);
  const [currentPageName, setCurrentPageName] = useState('');
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
 
  useEffect(() => {
    document.title = "stockwiseanalytics.com - Portfolio"; // Dynamically changes the title
  }, []);


/////////////
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  
    const updateWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateWindowSize);
  
      return () => {
        window.removeEventListener('resize', updateWindowSize);
      };
    }, []);
  // Fetch portfolio data
  useEffect(() => {
    const fetchPFData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username },
        });
        const data = res.data;
        setStocks(data);
        const symbols = data.map((stock) => stock.ctick);
        localStorage.setItem("symbolstring", symbols.join(","));
        
        const priceResponse = await axios.get(
          `${Base_URL_TV}/tvapimmvt?symbols=[${symbols.join(",")}]`
        );
        setCurrentPrices(priceResponse.data[0]);

        const volumeResponse = await axios.get(
          `${Base_URL_TV}/tvapi?symbols=[${symbols.join(",")}]`
        );
        
        // console.log('----volumes---'+ JSON.stringify(volumeResponse.data[0].volume));
        // setcurrentVolumes(volumeResponse.data.volume);


        const url = `${Base_URL_TV}/tvapi?symbols=[${symbols.join(",")}]`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            console.log("Received data:", data);
            const volumes = data.reduce((acc, stock) => {
              if (!stock.error) {
                acc[stock.symbol] = stock.volume;
           
                   }
              return acc;
            }, {});
             setcurrentVolumes(volumes);
          })

      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchPFData();
  }, [setStocks, setCurrentPrices]);

//   const calculateTotal = useCallback(async () => {
//     try {
//       let totalValue = 0;
//       let totalPFSizeValue = 0;
//       let calculatedTots = [];
//       let newGainStrings = [];
//       let newPfcompStrings = [];
//       let newlgndgainpercentage = [];
      
//       for (let i = 0; i < stocks.length; i++) {
//         const stock = stocks[i];
//         const label = stock.cname;
//         const quantity = stock.csemco_quantity;
//         const avgPrice = stock.avgprice;
//         const tick = stock.ctick;
//         await delay(2000); 
//         // Fetch current price for each stock
//         const matchingData = await fetchCPrice(tick);

//         if (matchingData) {
//           const lcpap = parseFloat(matchingData) - parseFloat(avgPrice);
//           const gain = lcpap * quantity;
//           const gainString = gain.toFixed(2);
//           totalValue += Math.abs(gain);
//           calculatedTots.push(((lcpap * 100) / parseFloat(avgPrice)).toFixed(2));
//           calculatedTots.push(gain.toFixed(2));
//           newGainStrings.push(Math.round(gainString));
//           const pfcomp = parseFloat(avgPrice) * quantity;
//           const lgndgainpercentage = ((lcpap * 100) / parseFloat(avgPrice)).toFixed(2);
//           newlgndgainpercentage.push(lgndgainpercentage);
//           const pfcompString = Math.round(pfcomp);
//           totalPFSizeValue += pfcomp;
//           newPfcompStrings.push(Math.round(pfcomp));
//         }
//       }
//       setTotalpfsize(totalPFSizeValue);
//       setTotalGain(totalPFSizeValue);
//       setTotal(totalValue);
//       //setTots(calculatedTots);
//       setTots(newGainStrings);
//       setPfcompStrings(newPfcompStrings);
//     } catch (error) {
//       console.error('Error calculating total:', error);
//     }
//   }, [stocks]);
  
  const calculateTotal = useCallback(async () => {
    try {
      let totalValue = 0;
      let totalPFSizeValue = 0;
      const calculatedTots = [];
      const newGainStrings = [];
      const newPfcompStrings = [];
      const newlgndgainpercentage = [];


  
      stocks.forEach((stock) => {
        const matchingPrice = currentPrices.find(
          (close_prices) =>
            close_prices.symbol.toLowerCase() === stock.ctick.toLowerCase()
        );
    
        if (matchingPrice) {
            const lcpap = parseFloat(matchingPrice.close_prices.cp90days) - parseFloat(stock.avgprice);
          const gain = lcpap * stock.csemco_quantity;
          totalValue += Math.abs(gain);
  
          calculatedTots.push(((lcpap * 100) / parseFloat(stock.avgprice)).toFixed(2));
          newGainStrings.push(Math.round(gain.toFixed(2)));
  
          const pfcomp = parseFloat(stock.avgprice) * stock.csemco_quantity;
          totalPFSizeValue += pfcomp;
          newPfcompStrings.push(Math.round(pfcomp));
          newlgndgainpercentage.push(
            ((lcpap * 100) / parseFloat(stock.avgprice)).toFixed(2)
          );
        }
      });
  
      setTotalpfsize(totalPFSizeValue);
      setTotalGain(totalPFSizeValue);
      setTotal(totalValue);
      //setTots(calculatedTots);
      setTots(newGainStrings);
      setPfcompStrings(newPfcompStrings);
    } catch (error) {
      console.error("Error calculating total:", error);
    }
  }, [stocks, currentPrices]);

    
  useEffect(() => {
    if (currentPrices.length > 0) {
      calculateTotal();
    }
  }, [currentPrices, calculateTotal]);

  useEffect(() => {
    if (totalGain> 0) {
      setIsLoading(false);
    }
  }, [stocks, totalGain]);

  

  const chartData = stocks.map((stock, index) => ({
    name: stock.cname,
    value: stock.csemco_quantity * stock.avgprice,
    tick: stock.ctick,
    color: backgColor[index]
  }));

 useEffect(() => {
    const updateChartCenter = () => {
      if (chartRef.current && typeof chartRef.current.getBoundingClientRect === 'function') {
        const chartBoundingBox = chartRef.current.getBoundingClientRect();
        const centerX = chartBoundingBox.width / 2;
        const centerY = chartBoundingBox.height / 2;
        setChartCenter({ x: centerX, y: centerY });
      }
    };
    updateChartCenter();
    window.addEventListener('resize', updateChartCenter);
    return () => window.removeEventListener('resize', updateChartCenter);
  }, []);
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: 'rgba(256,256,256, 0.3)',
        backgroundColor: 'rgba(256,256,256, 0.3)',
        height: 1,
      }}
    />
  );
  ////////
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function navigateTo(path) {
    navigate(path);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  function handleSelect_apd(evt) {
    navigateTo("/portfolio_apd");
  }

  function handleSelect_g_t(evt) {
    navigateTo("/portfolio_gain_today");
  }

  function handleSelect_g_lm(evt) {
    navigateTo("/portfolio_gain_last-month");
  }

  function handleSelect_g_ltm(evt) {
    navigateTo("/portfolio_gain_last-three-months");
  }
  // const handleShare = () => {
  //   let node = document.getElementById('Doughnutrefid');
  //   domtoimage.toPng(node)
  //     .then(dataUrl => {
  //       axios.post(`${BaseURL_twitter}`, { dataUrl: dataUrl })
  //         .then(res => {
  //           const url = res.data.imageUrl;
  //           console.log('---url---',url);
  //           const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
  //           window.open(twitterURL, "twitter");
  //         })
  //         .catch(err => console.log(err, "Error trying to tweet"));
  //     })
  //     .catch(err => console.log(err));
  // };

  const handleShare = () => {
    // Get the node element from DOM (your chart or image element)
    let node = document.getElementById('Doughnutrefid');
    
    // Convert the node to a PNG image using dom-to-image
    domtoimage.toPng(node)
      .then(dataUrl => {
        // Post the image (base64 encoded) to your backend for uploading to Twitter
        axios.post("https://api.stockwiseanalytics.com/image/imagetotweet", { dataUrl: dataUrl })
          .then(res => {
            // Assuming your backend returns the tweet URL
            const url = res.data.imageUrl;  // Ensure the backend is sending the correct URL
            const twitterURL = `https://twitter.com/share?url=${encodeURIComponent(url)}&text=Check%20out%20this%20image!&hashtags=yourHashtags`;
            
            // Open the Twitter share URL in a new window
            window.open(twitterURL, "twitter");
          })
          .catch(err => {
            console.error("Error trying to tweet", err);
            alert("Failed to share the image on Twitter.");
          });
      })
      .catch(err => {
        console.error("Error converting image", err);
        alert("Failed to convert the image for sharing.");
      });
  };

  
  const downloadChartAsImage = async () => {
    const datapdf = document.getElementById('Doughnutrefid');
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    try {
      html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
        var link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        // link.download = `portfolio_gainsnapshot_${currentDate}.png`;
        link.download = `portfolio_gainsnapshot_${currentDate}.png`;
        link.click();
      });
    } catch (error) {
      console.error('Error downloading the chart:', error);
      toast.error('Failed to download chart');
    }
  };
  
  const handleMenuClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  return (
    <>
   <div class="container-fluid px-4">
   <div className="row align-items-center bg-black  text-white centered-text-profilesettings"  style={{
           backgroundblendmode: 'exclusion',
           background: 'url(/images/Rectangle-2.png) lightgray 50% / cover no-repeat' ,
        width: `100`,
      }}>
           <div className="p-4"></div><div className="p-4"></div><div className="p-4"></div><div className="p-4"></div>
     <div class="col-lg-3 col-sm-12 col-xs-12">
            <div class="center-block">
            <div className="col" id="CSEMaverick">        
    <table className="table-borderless">  
         <tr>
        <td  colSpan="2" width=""></td>&nbsp;</tr>
       <tr>      
        <td className="d-flex justify-content-start border-0">
        <ImageDisplay imageId={userid}/>
        </td>
        <td className="justify-content-start  text-nowrap">
        <div className="row">
         <div className="online"><img className="rounded-1 span4 center-block"  src="/images/profile/vgeenclock.png"/><div className="ps-4">Online</div></div>
      </div>
      <div className="row">                                            
        <div className="joined-2-years-ago"><img className="rounded-1 span4 center-block" src="/images/profile/vbag.png"/>
        <div className="ps-4">Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
     
        </div>
      </div>
        </td>
    </tr>

</table>
</div>

            </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-xs-12">
            <div class="text-center">
              <div className="img-fluid d-md-none mx-auto d-block pt-2"></div>
            <p class="lh-sm">
  <span className="trackcss1">Track your investments</span>
  <span className="trackcss2">,</span>
  <span className="trackcss1"> assess risk</span>
  <span className="trackcss2"> and </span>
  <span className="trackcss1">make informed decisions</span>
  <span className="trackcss2 font-thin"> with our portfolio analysis tools. </span>
  <span className="trackcss1">Visualize your portfolio's performance</span>
  <span className="trackcss2"> with intuitive charts and metrics, empowering you to </span>
  <span className="trackcss1">optimize your investments</span>
  <span className="trackcss2"> and </span>
  <span className="trackcss1">achieve your financial goals with confidence</span>
  <span className="trackcss2">.</span>
  </p>

            </div>
        </div>
        <div class="col-lg-3 col-sm-12 col-xs-12">
            <div class="center-block">&nbsp;</div>
        </div>
        <div className="p-4"></div><div className="p-4"></div>
</div>
</div>

    {/* <div className="container-fluid">
<div className="row align-items-start bg-black  text-white centered-text-profilesettings"  style={{
           backgroundblendmode: 'exclusion',
           background: 'url(/images/Rectangle-2.png) lightgray 50% / cover no-repeat' ,
        width: `100`,
      }}>
<div className="p-1"></div><div className="p-4"></div><div className="p-4"></div><div className="p-2"></div>
<div className="row">
<div className="col-md-3">
    <div className="col" id="CSEMaverick">        
    <table className="table-borderless">  
         <tr>
        <td  colSpan="2" width=""></td>&nbsp;</tr>
       <tr>      
        <td className="d-flex justify-content-start border-0" style={{paddingLeft: '30px'}}>
        <ImageDisplay imageId={userid}/>
        </td>
        <td className="justify-content-start  text-nowrap">
        <div className="row">
         <div className="online"><img className="rounded-1 span4 center-block" style={{paddingLeft: '24px'}} src="/images/profile/vgeenclock.png"/><div className="ps-4">Online</div></div>
      </div>
      <div className="row">                                            
        <div className="joined-2-years-ago"><img className="rounded-1 span4 center-block" style={{paddingLeft: '24px'}} src="/images/profile/vbag.png"/>
        <div className="ps-4">Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
     
        </div>
      </div>
        </td>
    </tr>

</table>
</div>
</div>
<div className="col-md-6">
<div className="col text-center">
<div className="d-flex align-items-center justify-content-center">
<p class="lh-sm">
  <span className="trackcss1">Track your investments</span>
  <span className="trackcss2">,</span>
  <span className="trackcss1"> assess risk</span>
  <span className="trackcss2"> and </span>
  <span className="trackcss1">make informed decisions</span>
  <span className="trackcss2 font-thin"> with our portfolio analysis tools. </span>
  <span className="trackcss1">Visualize your portfolio's performance</span>
  <span className="trackcss2"> with intuitive charts and metrics, empowering you to </span>
  <span className="trackcss1">optimize your investments</span>
  <span className="trackcss2"> and </span>
  <span className="trackcss1">achieve your financial goals with confidence</span>
  <span className="trackcss2">.</span>
  </p>
</div>
   </div>
  </div>
  <div className="col-md-3"></div>
    </div>
    <div className="p-4"></div>  
    <div className="p-4"></div>  
    <div className="col">   
    </div>
  </div>
  </div>  */}
  <div class="container-fluid px-4">
  <div className="row  px-4">
  <p className="fininfo_title text-white fs-6 fw-bold font-family-sans-serif px-3">            
    PORTFOLIO PERFORMANCE
      </p>
      </div>
      <div className="row container">
      <div className="col-md-2 p-0"></div>
          <div className="col-md-8 p-0">
          <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    fontFamily: 'sans-serif',
                    position: 'realative', 
                    left: '1%',
                    bottom:'2%',
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/" style={{ fontFamily: 'Montserrat !important'}}>
            Home</Link>
        <Link underline="hover" color="white" href="/portfolio" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>
        Portfolio Analytics</Link>
        <Link underline="hover" color="white" href="/portfolio_gain" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>
        Gain</Link>
          <Typography color="white" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>Last Three Months</Typography>
   </Breadcrumbs>
          </div>
          <div className="col-md-2 p-0"></div>
        </div>
        <div className="row">
    <div className="col-md-2 p-0"></div>
        <div className="col-md-8 p-0">
      <div className="col-md-8 p-3 d-grid gap-2 d-md-flex d-flex justify-content-center  rounded-top" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',width: '100%'}}>
      <Button
          className="btn me-md-2 basic-button text-capitalize font-sans-serif fs-6 portfoliobtn btn-sq w-100"
          onClick={handleMenuClick}
               >
          Gain<ArrowDropDown />
        </Button>

        <Menu
          id="basic-menu"
          className="btn portfoliobtn"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigateTo("/portfolio");
              handleClose();
            }}
            className="menu-item portfoliobtnsel"
          >
            Gain Snapshot
          </MenuItem>
          <MenuItem
            onClick={handleSelect_apd}
            className="menu-item portfoliobtnsel"
          >
            Annual Projected Dividend
          </MenuItem>
        </Menu>

        <Button
          className="btn portfoliobtn me-md-2 basic-button text-capitalize font-sans-serif fs-6  btn-sq w-100"
          onClick={handleMenuClick2}
          style={{ fontFamily: 'sans-serif' }}
        >
         Till Today&nbsp;&nbsp;&nbsp;<ArrowDropDown />
        </Button>

        <Menu
          id="basic-menu2"
          className="btn portfoliobtn"
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
        >
     <MenuItem onClick={handleSelect_g_t} className="menu-item portfoliobtnsel">Till Today</MenuItem>
            {JSON.stringify(dateDifference.total_days) > 30  && (            
            <MenuItem onClick={handleSelect_g_lm} className="menu-item portfoliobtnsel">Last Month</MenuItem>
            )}
             {JSON.stringify(dateDifference.total_days) > 90  && ( 
            <MenuItem onClick={handleSelect_g_ltm} className="menu-item portfoliobtnsel">Last Three Months</MenuItem>
          )}
        </Menu>

        {/* <TwitterShareButton
          url={"https://www.stockwiseanalytics.com/"}
          title="Hey, I just shared my stocks"
          hashtags={["", ""]}
          onClick={handleShare}
          className="font-family-sans-serif  me-md-2"
        >
          <i className="btn portfoliobtn bi bi-twitter-x font-family-sans-serif fs-6" style={{ fontStyle: 'normal' }}>
            &nbsp;&nbsp;&nbsp;Share
          </i>
        </TwitterShareButton> */}
        <button className="btn portfoliobtn bi bi-download font-family-sans-serif fs-6 btn-sq css-1ujsas3" type="button" onClick={downloadChartAsImage}>
          &nbsp;&nbsp;&nbsp;Download
        </button>
      </div>
</div>
<div className="col-md-2 p-0"></div>
</div>

        <div className="row" id="Doughnutrefid">
        <div className="col-md-2 p-0"></div>
         <div className="col-md-4 p-0">
            <div ref={chartRef} style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)', height: "325px", position: "relative", marginBottom: "0%", padding: "0%" }} className="container d-flex align-items-center justify-content-center p-0 text-white font-family-sans-serif fontSize='12px'">
              {isLoading && (
                <div className="spinner-container">
                  <FadeLoader color="#ffffff" loading={isLoading} size={50} />
                </div>
              )}
<div style={{ width: '100%', height: 'auto', position: 'relative' }}>
<ResponsiveContainer width="100%" height={400}>
<PieChart ref={chartRef}>
  <Pie
     data={chartData}
     cx="50%"
     cy="50%"
     fontFamily= "sans-serif" 
     fontSize="9px"
     innerRadius={90}
     outerRadius={120}
     fill="#8884d8"
     dataKey="value"
     paddingAngle={2}
      minAngle={2}
      isAnimationActive={false}
    label={({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      value,
      index
    }) => {
      console.log("handling label?");
      const RADIAN = Math.PI / 180;
      // eslint-disable-next-line
      const radius = 25 + innerRadius + (outerRadius - innerRadius);
      // eslint-disable-next-line
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      // eslint-disable-next-line
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
        x={x}
        y={y}
        fill="#ffffff"
        style={{ color: 'white', fontSize: '10px', fontFamily: 'sans-serif'}}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        >
          {`${parseInt(chartData[index].value.toFixed(2)).toLocaleString()} LKR`}
        </text>
      );
    }}
  >
    {chartData.map((entry, index) => (
      <Cell
        key={`cell-${index}`}
        stroke="none"
        fill={backgColor[index % backgColor.length]}
      />
    ))}
  </Pie>

  <Tooltip
    wrapperStyle={{ fontFamily: 'sans-serif', fontSize: '12px' }}
    cursor={{
      stroke: "#BABABA",
      strokeWidth: 2,
      strokeDasharray: "5 5",
    }}
  
    content={({ active, payload }) => {
      if (active && payload && payload.length) {
        const { name, value, color } = payload[0].payload;
        return (
          <div className="text-center p-1" style={{ backgroundColor: color, fontFamily: 'sans-serif' , borderRadius: '5px' }}>
            <p style={{ fontFamily: 'sans-serif',fontSize: '12px', color: '#000000',fontWeight: 'bold'}}>{`${name}`}</p>
            <p style={{ fontFamily: 'sans-serif',fontSize: '12px', color: '#000000',fontWeight: 'bold'}}>{`${parseInt(value.toFixed(2)).toLocaleString()} LKR`}</p>
          </div>
        );
      }
      return null;
    }}
  />

<text x="50%" y="43%" fontSize="18px" textAnchor="middle" style={{ textAlign: 'center', color: 'white', fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 700, lineHeight: '22px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
    {totalGain !== 0 ? `${((parseInt(total) * 100) / parseInt(totalGain)).toFixed(2)}%` : '0%'}
  </text>
  
  <text x="50%" y="50%" fontSize="11px" style={{ color: 'white', fontsize: '11px', fontFamily: 'sans-serif', fontWeight: 300, lineHeight: '16px', whiteSpace: 'pre-wrap',}} textAnchor="middle" fill="white" dominantBaseline="middle">
    Portfolio Size
  </text>
  
  <text x="50%" y="57%" fontSize="14px" textAnchor="middle" style={{color: 'white', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 500, lineHeight: '16px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
    {Math.trunc(totalGain.toFixed(2)).toLocaleString()} LKR
  </text>
</PieChart>
      </ResponsiveContainer>
</div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center font-family-sans-serif" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',height: '325px', overflow: 'auto',paddingTop: '12px' }}>
       <div className="text-white text-opacity-20 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-0 py-2 px-3" style={{ width: 'auto', height: '270px', overflow: 'auto' }}>
       <div className="table-scrollable">
      <ul>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th className='tp_header'>Stock</th>
                    <th>&nbsp;&nbsp;</th>
                    <th className='tp_header'> Gain(LKR)</th>
                </tr>
            </thead>
            <tbody>
            {stocks.map((stock, index) => (
                         <tr>
                    <td className='tp_dtl'><li style={{ backgroundColor: backgColor[index], color: backgColor[index],fontFamily: 'sans-serif',fontSize:'20px', lineHeight: '14px', verticalAlign:'middle'}}></li></td>
                    <td className='tp_dtl'>{stock.cname} </td>
                    <td className='tp_dtl'>&nbsp;&nbsp;</td>
                    <td className='tp_dtl-val'> { parseInt(Tots[index]) ? Math.trunc(parseInt(Tots[index])).toLocaleString() : 'N/A'}</td>

                </tr>
              ))}
               </tbody>
        </table>
        </ul>
    </div>
</div>
 </div> 
       {/* <div className="col-md-4 d-flex align-items-center justify-content-center font-family-sans-serif" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',height: '325px', overflow: 'auto' }}>
            <ul>
            <table className="font-family-sans-serif text-white" style={{ fontSize: '12px',fontFamily: 'sans-serif'}}>
               <thead>
                 <tr>
                   <th></th>
                   <th className="text-start font-family-sans-serif">Stock</th>
                  <th className="text-center font-family-sans-serif">Gain(LKR)</th>
                 </tr>
               </thead>
               <tbody>
               {stocks.map((stock, index) => (
                  <tr key={index}>
                    <td><li style={{ backgroundColor: backgColor[index], color: backgColor[index],fontFamily: 'sans-serif',fontSize:'20px', lineHeight: '14px', verticalAlign:'middle'}}></li></td>
                    <td style={{ fontFamily: 'sans-serif',color: '#ffffff' }} className="text-nowrap text-start">{stock.cname}&nbsp;</td>
                    <td className="text-nowrap text-end" style={{ fontFamily: 'sans-serif' }}>&nbsp;{(parseInt(Tots[index])).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            </ul>
          </div> */}
          <div className="col-md-2 p-0"></div>
        </div>        
      </div>
      <div className="p-2"></div>  
      <div className="p-2"></div>  
      <div className="p-2"></div>  
      <div className="p-2"></div>  
       <div className="container-fluid ">
          <ColoredLine />
          <div className="row">
            <div className="col-md-7">
            <CurrentPortfolio/>
            </div>
            <div className="col-md-5">
              <PortfolioCreation />
            </div>
          </div>
               <ColoredLine />
          <div className="row">
            <div className="col-md-6 pt-2">
              <Todayspricevolume />
            </div>
            <div className="col-md-6 pt-2">
              <Linechart />
            </div>
          </div>
  
           <ColoredLine />
          <div className="row">
            <div className="col-md-12">
              <Finacialinfo />
            </div>
          </div>
          <ColoredLine />
 
          <div className="row">
            <div className="col-md-12">
              <CSENEWS />
            </div>
          </div>
          <ColoredLine />

          <div className="row">
            <div className="col-md-12">
              <researchinfo />
            </div>
          </div> 
          <div className="row">
            <div className="col-md-12">
              <DividentTracker />
            </div>
          </div> 
          <div className="row">
            <div className="col-md-12">
              <Marketsentiment />
            </div>
          </div> 
      </div> 
      <Browser size={size} />
    </>
  );
};

export default CombinedPortfolio;
